<template>

  <!-- id : contents : (S) -->
  <div id='contents' class="st-login" >
    <!-- class : content-wrap : (S) -->
    <section class="content-wrap st-center">
      <!-- class : main-wrap (S) -->
      <div class="main-wrap">
        <div class="login-find-wrap">
          <div class="tab-box">
            <router-link to="/find/findId" class="on">아이디 찾기</router-link>
            <router-link to="/find/findPwdId">비밀번호 재설정</router-link>
          </div>
          <div class="find-form-box">

            <div class="result-box">
              아이디 찾기 결과<br>
              {{ userName }} 님의 아이디는 <strong>{{ userId }}</strong> 입니다.
            </div>
          </div>
          <div class="btn-box st-result">
            <router-link to="/login" class="btn-submit" >로그인</router-link>
            <router-link to="/find/findPwdId" class="btn-submit st2">비밀번호 재설정</router-link>
          </div>
        </div>
      </div>
      <!-- class : main-wrap (E) -->


    </section>
    <!-- class : content-wrap : (E) -->
  </div>
  <!-- id : contents : (E) -->

</template>

<script>
export default {
  name: "findIdResult",
  data(){
    return {
      userName: this.$route.params.userName,
      userId: this.$route.params.userId
    }
  },
  methods:{
    onClickFindId(){
      window.location.href = `/find/findId`
    },
    onClickFindPwdId(){
      window.location.href = `/find/findPwdId`
    }
  }
}
</script>

<style scoped>

</style>